<template>
  <div>

    <parallax image="landing-home.jpg"></parallax>

    <module anchor="news" class="news">
      <v-row>
        <v-col cols="12" md="9" class="news-info">
          <h2>News</h2>
          <p v-html="information"></p>
        </v-col>
        <v-col cols="12" md="3" class="news-events">
          <h2>Nächster Event</h2>
          <events :next="true"/>
        </v-col>
      </v-row>
    </module>

    <module color="green" anchor="about">
      <h1>Über mich</h1>
      <v-row>
        <v-col cols="12" md="3">
          <v-img src="./../../assets/images/christiane.jpg" class="grey lighten-2" width="100%">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <v-col cols="12" md="9" class="pl-md-6 pt-6 pt-md-3" style="margin-top: -.4em">
          <p>
            Eine meiner Sternstunden war sicher die, in der ich die Sterne (wieder) für mich entdeckt habe. Und das,
            kurz nachdem ich in der Pandemie Phase meinen «Raum 18», mein mit viel Liebe und Herzblut aufgebautes
            Yogastudio und Begegnungszentrum, schliessen musste. Offensichtlich durfte/musste ich zunächst loslassen, um
            Raum zu haben für das Neue, das jetzt mit voller-, und erfüllender «Wucht» in mein Leben kam: Die Astrologie
            der neuen Zeit.
          </p>
          <p>
            Je tiefer ich in diese Astrologie eintauchen durfte, desto mehr begann alles miteinander zu tanzen, rund zu
            werden und Sinn zu ergeben - die erfüllenden, sowie die herausfordernden Themen in meinem Leben.
          </p>
          <p>
            So Vieles durfte mir endlich (wieder) bewusst werden. Zum Beispiel, warum mein Weg mich immer tiefer in die
            Verbindung mit der geistigen Welt,- sowie in ein tiefes Urvertrauen hineinführt und dass ich meiner
            Intuition wirklich vertrauen darf. Auch wurde mir bewusst, warum Bewegung und Tanz mich schon seit meiner
            Kindheit faszinieren und mir Kraft und Lebensfreude schenken. Aber vor allem durfte ich erkennen, weshalb
            achtsame, ehrliche und klare Kommunikation für mich so eine grosse Bedeutung hat.
          </p>
          <p>
            Die Astrologie hat für mich das Unsichtbare sichtbar gemacht, sie war und ist für mich selbst ein absolut
            grossartiges Werkzeug, um mich selbst bewusster kennenzulernen und zu verstehen. Dank all diesem Erkennen
            und Ent-wickeln spüre ich, dass es für mich jetzt an der Zeit ist, mich in meiner Essenz zu zeigen und meine
            Stimme (wieder) hörbar zu machen. Um mit dieser, meiner ganz individuellen Stimme, achtsam, unterstützend
            und klar das zu teilen und in die Welt zu bringen, was mir am Herzen liegt:
          </p>
          <p>
            Und zwar, auch Dich zu bewegen. Dahin, (wieder) zu der Version Deiner Selbst zu werden, die Du unter all den
            Schichten von Glaubenssätzen, Rollen und Erwartungen wirklich bist. Die Version Deiner Selbst, die Dich mit
            Freude, Kraft und Mut erfüllt. Die Dich als einzigartigen Stern strahlen lässt. Als Stern, welcher in seiner
            Einzigartigkeit, gemeinsam mit all den anderen unendlich vielen einzigartigen Sternen, unseren Sternenhimmel
            in seiner Ganzheit zum Leuchten bringt.
          </p>
          <p>
            Erden kann ich mich in der Natur. Dort finde ich Ruhe, Kraft und Inspiration. Und je bewusster ich selbst
            die Rhythmen und Zyklen der Natur erlebe, desto mehr finde ich auch Zugang zu den Zyklen-, Lebens-, und
            Entwicklungsphasen in unserem Leben als Mensch hier auf dieser wundervollen Mutter Erde. Ich spüre mich
            eingebettet in die Elemente und verbunden zwischen Himmel und Erde.
          </p>
          <p>
            Aus all diesem Verstehen und Erkennen, diesen Momenten des Aufleuchtens und der Klarheit erwächst mein Tun.
            Nimmt mein Weg und mein Angebot auch im Aussen immer klarer Form an. Mein aktuelles Angebot findest Du unter
            <a href="/astro">Astrologie</a>, <a href="/yoga">Yoga</a>, <a href="/events">Rituale</a> und <a
              href="/retreats">Retreats</a>.
          </p>
          <p>
            Ich freu mich sehr auf Dich!
          </p>
          <p>
            <a :href="getFileSrc('Lebenslauf.pdf')" target="_blank" rel="noopener">
              Stationen auf meinem Entwicklungsweg
            </a>
          </p>
        </v-col>
      </v-row>
    </module>

  </div>
</template>

<script>

  import Module from "./Module";
  import Parallax from "./Parallax";
  import Events from "./Events.vue";

  import {addLinks, getFileSrc} from "@/utils";

  export default {
    components: {
      Module,
      Parallax,
      Events
    },
    data() {
      return {
        information: "",
        event: null
      }
    },
    methods: {
      async getEvent() {
        this.event = (await this.axios.get("events/next")).data
        this.scroll();
      },
      async getInformation() {
        const {data} = await this.axios.get("config/news")
        this.information = addLinks(data.value || "Hier halte ich Euch auf dem laufenden");
        this.scroll();
      },
      scroll() {
        // Fix Scroll to Anchor after populating News
        if (this.$route.hash === "#news")
          this.$nextTick().then(() => window.scrollTo(0, document.getElementById("news").offsetTop));
      },
      getFileSrc
    },
    beforeMount() {
      this.getEvent();
      this.getInformation();
    }
  }

</script>

<style lang="scss">

  .news {

    h1 {
      margin-bottom: -4px;
    }

    .news-info {
      text-align: left; // Needed for Safari
      overflow-x: hidden;
      white-space: pre-line;
    }
  }

  @media (min-width: 960px) {
    .news-info {
      padding-right: 24px;
    }
    .news-events {
      padding-left: 24px;
    }
  }

</style>
